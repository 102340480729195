// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chrono-js": () => import("./../src/pages/chrono.js" /* webpackChunkName: "component---src-pages-chrono-js" */),
  "component---src-pages-entity-js": () => import("./../src/pages/entity.js" /* webpackChunkName: "component---src-pages-entity-js" */),
  "component---src-pages-hiragana-js": () => import("./../src/pages/hiragana.js" /* webpackChunkName: "component---src-pages-hiragana-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kanji-js": () => import("./../src/pages/kanji.js" /* webpackChunkName: "component---src-pages-kanji-js" */),
  "component---src-pages-keyword-js": () => import("./../src/pages/keyword.js" /* webpackChunkName: "component---src-pages-keyword-js" */),
  "component---src-pages-morphological-js": () => import("./../src/pages/morphological.js" /* webpackChunkName: "component---src-pages-morphological-js" */)
}

